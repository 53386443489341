// src/utils.js

export const getOrCreateUserId = () => {
  const userId = localStorage.getItem('userId');
  return userId || null;
};

export const saveUserId = (userId) => {
  localStorage.setItem('userId', userId);
};
