// src/api.js
import config from './config';

const PLAYFAB_TITLE_ID = config.titleId; // ID de titre PlayFab depuis le fichier de configuration

// Fonction pour se connecter avec un ID personnalisé
export const loginWithCustomId = async (customId) => {
  console.log(`Logging in with custom ID: ${customId}`);
  const response = await fetch(`https://${PLAYFAB_TITLE_ID}.playfabapi.com/Client/LoginWithCustomID`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      TitleId: PLAYFAB_TITLE_ID,
      CustomId: customId,
      CreateAccount: true,
    }),
  });

  const data = await response.json();
  console.log('Login response:', data);
  if (data.code === 200) {
    localStorage.setItem('playFabId', data.data.PlayFabId);
    localStorage.setItem('sessionTicket', data.data.SessionTicket);
  } else {
    throw new Error('Failed to log in with custom ID');
  }
  return data;
};

// Fonction pour obtenir les statistiques du joueur
export const getPlayerStatistics = async (sessionTicket) => {
  console.log(`Fetching player statistics with session ticket: ${sessionTicket}`);
  const response = await fetch(`https://${PLAYFAB_TITLE_ID}.playfabapi.com/Client/GetPlayerStatistics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': sessionTicket,
    },
  });

  const data = await response.json();
  console.log('Player statistics response:', data);
  if (data.code !== 200) {
    throw new Error('Failed to get player statistics');
  }
  return data;
};

// Fonction pour créer un ID utilisateur
export const createUserId = async (userId) => {
  console.log('Creating new user ID');
  const response = await fetch('https://trials-xrserver.com/editor/console/Server/SetPlayerDisplayName', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }), // Envoi du userId pour création
  });

  const data = await response.json();
  console.log('Create user response:', data);
  if (!response.ok) {
    throw new Error('Failed to create user ID');
  }
  return data;
};

// Fonction pour obtenir le nom d'affichage de l'utilisateur
export const getUserDisplayName = async (sessionTicket) => {
  console.log(`Fetching user display name with session ticket: ${sessionTicket}`);
  const response = await fetch(`https://${PLAYFAB_TITLE_ID}.playfabapi.com/Client/GetAccountInfo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': sessionTicket,
    },
  });

  const data = await response.json();
  console.log('User display name response:', data);
  if (data.code !== 200) {
    throw new Error('Failed to get user display name');
  }
  return data;
};

// Fonction pour mettre à jour le nom d'affichage de l'utilisateur
export const updateUserDisplayName = async (sessionTicket, newDisplayName) => {
  console.log(`Updating user display name with session ticket: ${sessionTicket}`);
  const response = await fetch(`https://${PLAYFAB_TITLE_ID}.playfabapi.com/Client/UpdateUserTitleDisplayName`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': sessionTicket,
    },
    body: JSON.stringify({ DisplayName: newDisplayName }),
  });

  const data = await response.json();
  console.log('Update user display name response:', data);
  if (data.code !== 200) {
    throw new Error('Failed to update user display name');
  }
  return data;
};
