// src/App.js

import React, { useEffect, useState } from 'react';
import UserProfile from './components/UserProfile';
import { getOrCreateUserId, saveUserId } from './utils';
import { loginWithCustomId } from './api';
import './index.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Profile from './Profile';

const App = () => {
  const [customId, setCustomId] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = getOrCreateUserId();
    if (!userId) {
      setIsNewUser(true);
      setLoading(false);
      return;
    }

    loginUser(userId);
  }, []);

  const loginUser = async (userId) => {
    setLoading(true);
    try {
      await loginWithCustomId(userId);
      saveUserId(userId); // Assurez-vous que le userId est enregistré
      setLoading(false);
    } catch (error) {
      console.error('An error occurred during login:', error);
      setLoading(false);
    }
  };

  const handleCustomIdSubmit = (event) => {
    event.preventDefault();
    saveUserId(customId);
    loginUser(customId);
  };

  if (loading) {
    return <div className="terminal">Loading...</div>;
  }

  return (
    <Router>
      <main className="content-animation">
        <h1 className="main-title">Operation Skywatch</h1>
        <h2 className="sub-title">Created by Alice & Smith</h2>
        {isNewUser ? (
          <>
            <h1 className="title">Create Your Custom ID</h1>
            <form onSubmit={handleCustomIdSubmit}>
              <input
                type="text"
                value={customId}
                onChange={(e) => setCustomId(e.target.value)}
                placeholder="Enter your custom ID"
                className="input-field"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </>
        ) : (
          <>
            <UserProfile />
            <Link to="/profile">
              <button className="start-button">Start</button>
            </Link>
          </>
        )}
        <footer className="footer">
          <img
            src="https://cdn.aliceandsmith.com/wp-content/uploads/2017/11/28183130/logo-aliceandsmith.png"
            alt="Alice & Smith Logo"
            className="footer-logo"
          />
        </footer>
        <Routes>
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </main>
    </Router>
  );
};

export default App;
