// src/Profile.js

import React, { useEffect, useState } from 'react';
import { getPlayerStatistics, getUserDisplayName } from './api';
import './Profile.css';

const Profile = () => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const sessionTicket = localStorage.getItem('sessionTicket'); // Obtenez le sessionTicket du stockage local

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        if (!sessionTicket) {
          throw new Error('No session ticket available');
        }

        // Obtenez le nom d'affichage de l'utilisateur
        const displayNameData = await getUserDisplayName(sessionTicket);
        console.log('User display name:', displayNameData.data.AccountInfo.TitleInfo.DisplayName);

        // Récupérez les statistiques du joueur
        const statsData = await getPlayerStatistics(sessionTicket);
        setStatistics(statsData.data.Statistics); // Assurez-vous que c'est `data.Statistics` qui contient les statistiques
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch player statistics');
        setLoading(false);
        console.error('Failed to fetch player statistics:', error);
      }
    };

    fetchStatistics();
  }, [sessionTicket]);

  if (loading) {
    return <div className="terminal">Loading...</div>;
  }

  return (
    <main className="profile-container">
      <h1>Profile Customization</h1>
      <p>Here you can customize your profile and view your statistics.</p>
      {error && <div className="error-message">{error}</div>}
      <h2>Player Statistics</h2>
      <ul>
        {statistics.map(stat => (
          <li key={stat.StatisticName}>
            {stat.StatisticName}: {stat.Value}
          </li>
        ))}
      </ul>
    </main>
  );
};

export default Profile;
