// src/components/UserProfile.js

import React, { useState, useEffect } from 'react';
import { getUserDisplayName, updateUserDisplayName } from '../api';
import '../index.css';  // Assurez-vous que index.css est importé si nécessaire

const UserProfile = () => {
  const [displayName, setDisplayName] = useState('');
  const [newDisplayName, setNewDisplayName] = useState('');
  const [sessionTicket, setSessionTicket] = useState(localStorage.getItem('sessionTicket'));
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDisplayName = async () => {
      try {
        const data = await getUserDisplayName(sessionTicket);
        setDisplayName(data.data.AccountInfo.TitleInfo.DisplayName);
      } catch (error) {
        console.error('Error fetching display name:', error);
      }
    };

    fetchDisplayName();
  }, [sessionTicket]);

  const handleUpdateDisplayName = async () => {
    setLoading(true);
    setError('');
    try {
      await updateUserDisplayName(sessionTicket, newDisplayName);
      setDisplayName(newDisplayName);
      setLoading(false);
    } catch (error) {
      setError('Error updating display name.');
      setLoading(false);
    }
  };

  return (
    <div className="user-profile">
      <h2 className="title">User Profile</h2>
      <p className="current-display-name">Current Display Name: {displayName}</p>
      <input 
        type="text" 
        value={newDisplayName} 
        onChange={(e) => setNewDisplayName(e.target.value)} 
        placeholder="Enter new display name" 
        className="input-field"
      />
      <button onClick={handleUpdateDisplayName} disabled={loading}>
        {loading ? 'Updating...' : 'Update Display Name'}
      </button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default UserProfile;
